<template>
  <div class="c-container-border position-relative z4">
    <div class="c-datatable-header">
      <div class="c-container-border-title">
        {{ collection.question }}
      </div>
    </div>

    <div class="c-datatable">
      <v-data-table
        :headers="headers"
        :items="collection.arrAnswer"
        :search="search"
        :items-per-page="perPage"
        :page="page"
        fixed-header
        hide-default-footer
        dense
        @pagination="onPageChanged"
      >
        <template v-slot:[`item.number`]="{ item }">
          {{ collection.arrAnswer.indexOf(item) + 1 }}
        </template>

        <template v-slot:[`item.image`]="{ item }">
          <div
            v-if="item.image"
            class="c-image shadow-sm my-3"
            :style="{ 'background-image': `url('${item.image}')` }"
          ></div>
          <div v-if="!item.image">
            -
          </div>
        </template>
      </v-data-table>

      <div class="d-flex justify-content-between mt-4">
        <div>
          <b-input-group>
            <template #prepend>
              <b-input-group-text>
                <b-icon-search></b-icon-search>
              </b-input-group-text>
            </template>
            <b-form-input
              class="form-control form-control-solid h-auto py-2 pl-3 pr-7"
              placeholder="Search"
              v-model="search"
            ></b-form-input>
          </b-input-group>
        </div>

        <div
          class="c-datatable-page d-flex align-items-center justify-content-center"
        >
          <div
            v-ripple
            v-on:click="page > 1 ? (page -= 1) : undefined"
            class="prev-next-btn"
          >
            <img :src="`${baseAppPath}/media/chevron-left.png`" height="16px" />
          </div>
          <b-form-input
            class="h-auto text-center mx-1 py-2"
            style="height: 40px; width: 50px;"
            v-model="page"
          ></b-form-input>
          <div
            v-ripple
            v-on:click="
              page < pagination.pageCount ? (page = parseInt(page) + 1) : null
            "
            class="prev-next-btn"
          >
            <img
              :src="`${baseAppPath}/media/chevron-left.png`"
              style="transform: rotate(180deg)"
              height="16px"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.c-image {
  width: 70px;
  height: 70px;
  border-radius: 10px;
  background-size: cover;
  cursor: pointer;
}

.per-page-wrapper {
  display: inline-flex;
  color: #a29f9d;
  font-size: 12px;
  font-weight: bold;
  border: 1px solid #d7d4d3;
  border-radius: 12px;

  div:nth-child(1),
  div:nth-child(3) {
    display: flex;
    width: 65px;
    align-items: center;
    justify-content: center;
    // padding: 6px 14px;
  }
  div:nth-child(3) {
    cursor: pointer;
  }
}

.c-datatable-header {
  input {
    background-color: #ffffff !important;
    border: 1px solid #d7d4d3 !important;
    border-left: 0px solid #d7d4d3 !important;
    border-radius: 0px 8px 8px 0px !important;
  }
}

.c-datatable-page {
  .prev-next-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 33px;
    height: 33px;
    border-radius: 4px;
    cursor: pointer;

    img {
      display: block;
      height: 16px;
    }
  }
}
</style>

<script>
import Moment from "moment";

export default {
  props: ["collection"],
  components: {},
  data() {
    return {
      search: "",
      headers: [
        { text: "No", value: "number", width: "60px" },
        { text: "Gambar", value: "image", width: "100%" }
        // { text: 'Time Updated', value: 'updated_at', width: '130px' },
        // { text: 'Updated by', value: 'name_user', width: '100%' },
      ],
      data: [],
      selectedData: {},

      loading: true,
      page: 1,
      perPage: 5,
      pagination: {
        pageCount: 1
      },

      errors: []
    };
  },

  mounted() {},

  methods: {
    formatDate(date) {
      return Moment(date).format("DD-MM-YYYY");
    },
    formatTime(date) {
      return Moment(date).format("HH:mm:ss");
    },

    onPageChanged(page) {
      this.pagination = page;
    }
  }
};
</script>
