<template>
  <div class="c-container-border position-relative z4">
    <div class="c-datatable-header">
      <div class="c-container-border-title">
        Jenis Kelamin
      </div>
    </div>

    <div class="c-datatable">
      <v-data-table
        :headers="headers"
        :items="data"
        :search="search"
        :items-per-page="perPage"
        :page="page"
        fixed-header
        hide-default-footer
        dense
      >
        <template v-slot:[`item.active`]="{ item }">
          <div class="custom-control custom-switch">
            <input
              v-model="item.active"
              v-on:click="openConfirmationDialog(item)"
              size="lg"
              type="checkbox"
              class="custom-control-input"
              :id="'customSwitches_' + item.id"
            />
            <label
              class="custom-control-label"
              :for="'customSwitches_' + item.id"
            ></label>
          </div>
        </template>
        <template v-slot:[`item.actions`]>
          <div class="c-datatable-actions d-flex">
            <div
              v-ripple
              class="c-datatable-view"
              v-on:click="programDialog = true"
            >
              <img :src="`${baseAppPath}/media/view-icon.svg`" />
            </div>
            <div v-ripple class="c-datatable-edit">
              <img :src="`${baseAppPath}/media/edit-icon.svg`" />
            </div>
            <div v-ripple class="c-datatable-delete">
              <img :src="`${baseAppPath}/media/delete-icon.svg`" />
            </div>
          </div>
        </template>
      </v-data-table>

      <div class="d-flex justify-content-between mt-4">
        <div>
          <b-input-group>
            <template #prepend>
              <b-input-group-text>
                <b-icon-search></b-icon-search>
              </b-input-group-text>
            </template>
            <b-form-input
              class="form-control form-control-solid h-auto py-2 pl-3 pr-7"
              placeholder="Search"
              v-model="search"
            ></b-form-input>
          </b-input-group>
        </div>

        <div
          class="c-datatable-page d-flex align-items-center justify-content-center"
        >
          <div
            v-ripple
            v-on:click="page > 1 ? (page -= 1) : undefined"
            class="prev-next-btn"
          >
            <img :src="`${baseAppPath}/media/chevron-left.png`" height="16px" />
          </div>
          <b-form-input
            class="h-auto text-center mx-1 py-2"
            style="height: 40px; width: 50px;"
            v-model="page"
          ></b-form-input>
          <div v-ripple v-on:click="page += 1" class="prev-next-btn">
            <img
              :src="`${baseAppPath}/media/chevron-left.png`"
              style="transform: rotate(180deg)"
              height="16px"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.custom-switch {
  transform: scale(2.2) translateX(8px);
}

.per-page-wrapper {
  display: inline-flex;
  color: #a29f9d;
  font-size: 12px;
  font-weight: bold;
  border: 1px solid #d7d4d3;
  border-radius: 12px;

  div:nth-child(1),
  div:nth-child(3) {
    display: flex;
    width: 65px;
    align-items: center;
    justify-content: center;
    // padding: 6px 14px;
  }
  div:nth-child(3) {
    cursor: pointer;
  }
}

.c-datatable-header {
  input {
    background-color: #ffffff !important;
    border: 1px solid #d7d4d3 !important;
    border-left: 0px solid #d7d4d3 !important;
    border-radius: 0px 8px 8px 0px !important;
  }
}

.tab-item {
  padding: 5px;
  width: 80px;
  margin: 0px 5px;
  color: #06b56f;
  font-size: 12px;
  text-align: center;
  border: 1px solid #06b56f;
  border-radius: 8px;
  cursor: pointer;
}
.tab-item-active {
  color: #ffffff;
  font-weight: bold;
  background: #06b56f;
}

.c-datatable-page {
  .prev-next-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 33px;
    height: 33px;
    border-radius: 4px;
    cursor: pointer;

    img {
      display: block;
      height: 16px;
    }
  }
}
</style>

<script>
import { mapState } from "vuex";
import { validationMixin } from "vuelidate";
import { email, minLength, required } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  props: [
    "dialog",
    "closeDialog",
    "confirmationDialog",
    "cofirmationDialogOpened"
  ],
  components: {},
  data() {
    return {
      search: "",
      headers: [
        { text: "Date", value: "date", width: "100px" },
        { text: "Time", value: "time", width: "80px" },
        { text: "Submited by", value: "submited_by", width: "180px" },
        { text: "Jenis Kelamin", value: "answer", width: "100%" }
        // { text: 'Action', value: 'actions', sortable: false },
      ],
      data: Array.from({ length: 100 }, (_v, i) => {
        return {
          id: i,
          date: "19/10/2021",
          time: "10:15:00",
          submited_by: "Lorem Ipsum Dolor Sit",
          answer: "Laki-Laki",
          active:
            Math.floor(Math.random() * (1 - 0 + 1)) + 0 == 1 ? true : false
        };
      }),
      selectedData: {},

      form: {
        deskripsi: null,
        duration_start: null,
        duration_end: null,
        logo: null,
        active: true,

        fullname: null,
        email: null,
        username: null,
        role: "True",
        contact: null
      },

      logoTemp: null,

      loading: true,
      page: 1,
      perPage: 5,

      programDialog: false,
      errors: []
    };
  },
  validations: {
    form: {
      fullname: {
        required,
        minLength: minLength(1)
      },
      email: {
        required,
        email
      },
      username: {
        required,
        minLength: minLength(1)
      },
      role: {
        required
      },
      contact: {
        required,
        minLength: minLength(1)
      },
      active: {
        required
      },
      logo: {
        required,
        minLength: minLength(1)
      }
    }
  },

  watch: {
    logo(val) {
      this.logoTemp = URL.createObjectURL(val);
    }
  },

  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form = {
        fullname: null,
        email: null,
        username: null,
        role: null,
        contact: null
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    changeStatus(isConfirm) {
      if (!isConfirm) {
        this.selectedData.active = !this.selectedData.active;
      }
      this.cofirmationDialogOpened(false);
    },
    openConfirmationDialog(item) {
      this.selectedData = item;
      this.cofirmationDialogOpened(true);
    },
    strippedContent(data) {
      let regex = /(<([^>]+)>)/gi;
      return data?.replace(regex, "");
    }
  },
  computed: {
    ...mapState({
      errors: state => state.account.errors
    }),
    logo() {
      return this.form.logo;
    }
  }
};
</script>
