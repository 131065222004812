<template>
  <div class="c-container-border position-relative z4">
    <div class="c-datatable-header">
      <div class="c-container-border-title">
        {{ collection.question }}
      </div>
    </div>

    <div class="c-datatable pt-4">
      <PieChart class="c-chart" :chart-data="datacollection"></PieChart>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.c-datatable-header {
  input {
    background-color: #ffffff !important;
    border: 1px solid #d7d4d3 !important;
    border-left: 0px solid #d7d4d3 !important;
    border-radius: 0px 8px 8px 0px !important;
  }
}

.c-chart {
  max-width: 100%;
  max-height: 250px;
}
</style>

<script>
import PieChart from "./PieChart.js";

export default {
  props: ["collection"],
  components: {
    PieChart
  },
  data() {
    return {
      datacollection: null
    };
  },

  mounted() {
    this.datacollection = {
      labels: Array.from(
        this.collection.arrAnswer.filter(el => el.answer != null),
        el => el.answer
      ),
      datasets: [
        {
          label: "Data One",
          backgroundColor: [
            "#55c3fe",
            "#ff729d",
            "#D5F30B",
            "#FFEC00",
            "#FFAF00",
            "#FF7300",
            "#FF0000",
            "#E01E84",
            "#C758D0",
            "#9C46D0",
            "#8E6CEF"
          ],
          data: Array.from(
            this.collection.arrAnswer.filter(el => el.answer != null),
            el => el.total
          )
        }
      ]
    };

    // const config = {
    //   type: 'pie',
    //   data: data,
    //   options: {
    //     responsive: true,
    //     plugins: {
    //       legend: {
    //         position: 'top',
    //       },
    //       title: {
    //         display: true,
    //         text: 'Chart.js Pie Chart'
    //       }
    //     }
    //   },
    // };

    // this.renderChart(data, options)
  },

  methods: {
    onPageChanged(page) {
      this.pagination = page;
    }
  }
};
</script>
