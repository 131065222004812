<template>
  <div v-if="data">
    <div class="breadcrumb-text">
      Survey Online / Daftar {{ routesName[activeTabIndex] }} / Survey A
    </div>

    <div class="tab-container">
      <div
        v-ripple
        class="tab-item"
        :class="{ 'tab-item-active': activeTabIndex == 1 }"
        v-on:click="$router.back()"
      >
        &#60; Back
      </div>
    </div>

    <div v-if="activeTabIndex === 0">
      <div class="table-container">
        <div class="survey-title">{{ data.name_survey }}</div>
        <div class="survey-desc" v-html="data.desc_survey"></div>

        <div class="d-flex align-items-center justify-content-between my-2">
          <div>
            <div>
              Created by:
              <span class="font-weight-bolder">{{ data.created_by_name }}</span>
            </div>
            <div>
              Created date:
              <span class="font-weight-bolder">{{ data.created_at }}</span>
            </div>
          </div>

          <button
            class="btn btn-white d-block border-gray font-weight-bolder px-10 py-5 mb-3 mt-5 font-size-3"
            style="border-radius: 14px; border: 1px solid #D7D4D3 !important;"
            v-ripple
          >
            <img
              :src="`${baseAppPath}/media//peoples.svg`"
              width="18px"
              class="mr-4"
            />
            Surveyor
          </button>
        </div>

        <b-row>
          <b-col cols="12" sm="7">
            <div class="d-flex align-items-center">
              <vue-select
                v-model="selectedOption"
                :options="options"
                placeholder="Search Question"
                class="c-select-question"
                style="min-width: 200px; flex: 1"
              >
              </vue-select>

              <router-link
                :to="`/survey-online/detail/${id_user}/${id}/questioner`"
              >
                <button
                  class="btn btn-secondary d-block border-gray font-weight-bolder ml-4 px-10 py-5 mb-3 mt-3 font-size-3"
                  style="border-radius: 14px; border: 1px solid #D7D4D3 !important;"
                  v-ripple
                >
                  <img
                    :src="`${baseAppPath}/media/edit-icon.svg`"
                    width="12px"
                    class="mr-2"
                    style="filter: brightness(100)"
                  />
                  Kuisioner
                </button>
              </router-link>
            </div>

            <template v-for="(item, index) in summary.arrCollection">
              <Essay
                :key="'q-' + index"
                v-if="item.type_question == 1"
                :collection="item"
              />

              <MultipleChoice
                :key="'q-' + index"
                v-if="item.type_question == 2"
                :collection="item"
              />

              <ImageQuestion
                :key="'q-' + index"
                v-if="item.type_question == 5"
                :collection="item"
              />
            </template>

            <GenderTable
              :idSurvey="id"
              :dialog="dialog"
              :closeDialog="() => (dialog = false)"
              :confirmationDialog="confirmationDialog"
              :cofirmationDialogOpened="opened => (confirmationDialog = opened)"
            />
          </b-col>
          <b-col cols="12" sm="5">
            <LogTable
              :idSurvey="id"
              :dialog="dialog"
              :closeDialog="() => (dialog = false)"
              :confirmationDialog="confirmationDialog"
              :cofirmationDialogOpened="opened => (confirmationDialog = opened)"
            />
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.c-container-border {
  padding: 12px;
  margin-bottom: 20px;
  border: 3px solid #f7f7f7;
  border-radius: 20px;
}
.c-container-border-title {
  padding-bottom: 10px;
  text-align: center;
  font-size: 19px;
  // color: #A29F9D;
  color: #000000;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.c-select-question {
  input {
    padding: 8px !important;
  }
}

.c-datatable {
  table {
    border-bottom: thin solid rgba(0, 0, 0, 0.12);
    border-bottom-width: thin;
    border-bottom-style: solid;
    border-bottom-color: rgba(0, 0, 0, 0.12);

    thead {
      tr {
        th {
          font-style: normal !important;
          font-weight: normal !important;
          font-size: 11.5px !important;
          color: #a29f9d !important;
        }
      }
    }
    tbody {
      tr {
        td {
          height: 40px !important;
          font-style: normal !important;
          font-weight: bold !important;
          font-size: 12px !important;
          color: #6c6867 !important;
        }
      }
    }
  }
  .custom-switch {
    display: inline-block;
    transform: scale(1.7) translateX(10px);
  }
  .c-datatable-actions {
    & > div {
      margin-right: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      border-radius: 4px;
      background-color: #f0efef;
      cursor: pointer;
    }
    &div:nth-last-child {
      margin-right: 0px;
    }

    .c-datatable-view {
      img {
        height: 10px;
      }
    }
    .c-datatable-view:hover {
      background-color: #2b97d4;

      img {
        filter: brightness(100);
      }
    }
    .c-datatable-edit:hover {
      background-color: #12d76d;

      img {
        filter: brightness(100);
      }
    }
    .c-datatable-delete:hover {
      background-color: #e32910;

      img {
        filter: brightness(100);
      }
    }

    img {
      height: 12px;
    }
  }
}

.c-file-input {
  .custom-file-input {
    height: inherit !important;
  }
  .custom-file-label {
    margin-bottom: 0px !important;
  }
}
</style>

<style lang="scss">
.btn-group {
  width: 100%;

  .dropdown-toggle.nav-link:after,
  .dropdown-toggle.btn:after {
    position: absolute !important;
    right: 15px !important;
    top: 41% !important;
  }
}

.tab-container {
  display: flex;
  margin-bottom: 15px;

  .tab-item {
    padding: 5px 28px;
    margin-right: 10px;
    color: #06b56f;
    font-size: 12px;
    border: 1px solid #06b56f;
    border-radius: 8px;
    cursor: pointer;
    transition: all 400ms;
    -webkit-box-shadow: 7px 7px 0px 0px rgba(6, 181, 111, 0);
    -moz-box-shadow: 7px 7px 0px 0px rgba(6, 181, 111, 0);
    box-shadow: 7px 7px 0px 0px rgba(6, 181, 111, 0);

    &:hover {
      -webkit-box-shadow: 3px 3px 10px 0px rgba(6, 181, 111, 0.3);
      -moz-box-shadow: 3px 3px 10px 0px rgba(6, 181, 111, 0.3);
      box-shadow: 3px 3px 10px 0px rgba(6, 181, 111, 0.3);
    }
  }
  .tab-item-active {
    color: #ffffff;
    font-weight: bold;
    background: #06b56f;
    -webkit-box-shadow: 7px 7px 16px 0px rgba(6, 181, 111, 0.63) !important;
    -moz-box-shadow: 7px 7px 16px 0px rgba(6, 181, 111, 0.63) !important;
    box-shadow: 7px 7px 16px 0px rgba(6, 181, 111, 0.63) !important;
  }
}

.table-container {
  position: relative;
  min-height: 80vh;
  padding: 20px;
  margin-right: 20px;
  border-radius: 16px;
  background-color: #ffffff;
  -webkit-box-shadow: 14px 0px 22px 0px rgba(59, 116, 92, 0.04);
  -moz-box-shadow: 14px 0px 22px 0px rgba(59, 116, 92, 0.04);
  box-shadow: 14px 0px 22px 0px rgba(59, 116, 92, 0.04);

  .survey-title {
    font-size: 20px;
    font-weight: bold;
    color: #6c6867;
  }
  .survey-desc {
    font-size: 16px;
    color: #6c6867;
  }
}

.c-dialog {
  position: relative;
  max-height: 80vh;

  .dialog-title {
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    color: #8c8c8c;
  }
  .form-group {
    margin-bottom: 10px;
  }
  .input-title {
    margin-bottom: 6px;
    color: #8c8c8c;
    font-size: 12px;
  }
  input {
    background-color: #ffffff !important;
    border: 1px solid #d7d4d3 !important;
    border-radius: 8px !important;
  }
  .c-select-input {
    input {
      padding-top: 4px;
      padding-bottom: 4px;
      background-color: inherit !important;
      border: inherit !important;
      border-radius: inherit !important;
    }
    ul {
      padding-left: 0px;
    }
    li {
      padding-left: 10px;
    }
  }
}
.dialog-close {
  position: absolute;
  top: 14px;
  right: 14px;
  padding: 14px;
  border-radius: 100px;
  background-color: #ffffff;
  cursor: pointer;
}

@media (max-width: 992px) {
}

@media (max-width: 768px) {
}

@media (max-width: 576px) {
}
</style>

<script>
import Moment from "moment";

import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

import LogTable from "./LogTable.vue";
import Essay from "./Essay.vue";
import MultipleChoice from "./MultipleChoice.vue";
import ImageQuestion from "./Image.vue";

import GenderTable from "./GenderTable.vue";

export default {
  name: "survey_online_detail",
  props: ["id", "id_user"],
  components: {
    LogTable,
    Essay,
    MultipleChoice,
    ImageQuestion,

    GenderTable
  },
  data() {
    return {
      baseUrl: ApiService.baseUrl,
      routesName: ["Data Survey"],
      activeTabIndex: 0,
      data: null,
      summary: {
        arrCollection: []
      },

      options: [
        "Pertanyaan 1",
        "Pertanyaan 2",
        "Pertanyaan 3",
        "Pertanyaan 4",
        "Pertanyaan 5",
        "Pertanyaan 6"
      ],
      selectedOption: null,

      dialog: false,
      confirmationDialog: false
    };
  },
  mounted() {
    ApiService.get(
      `api/dashboard/get?id_user=${this.id_user}&token=${JwtService.getToken(
        "survey"
      )}&id_survey=${this.id}`,
      "survey"
    )
      .then(({ data }) => {
        if (data?.response?.arrCollection) {
          this.summary = data?.response;
        }
      })
      .finally(() => {
        this.loading = false;
      });

    ApiService.get(
      `api/survey?id_user=${this.id_user}&id_survey=${
        this.id
      }&token=${JwtService.getToken("survey")}`,
      "survey"
    )
      .then(({ data }) => {
        if (data?.response?.arrSurvey?.length > 0) {
          this.data = data?.response?.arrSurvey[0];
        }
      })
      .finally(() => {
        this.loading = false;
      });
  },
  beforeDestroy() {},
  methods: {
    setBantuActiveTab(name, index) {
      this.activeTab = name;
      this.activeTabIndex = index;
    },
    setActiveTabIndex(index) {
      this.activeTabIndex = index;
    },
    formatDate(date) {
      return Moment(date).format("DD MMM YYYY [|] HH:mm [WIB]");
    },
    strippedContent(data) {
      let regex = /(<([^>]+)>)/gi;
      return data?.replace(regex, "");
    }
  }
};
</script>
