<template>
  <div class="c-container-border position-relative z4">
    <div class="c-datatable-header">
      <div class="c-container-border-title">
        Log Update Data Survey
      </div>
    </div>

    <div class="c-datatable">
      <v-data-table
        :headers="headers"
        :items="data"
        :sort-by.sync="columnName"
        :sort-desc.sync="isDescending"
        :search="search"
        :items-per-page="perPage"
        :page="page"
        fixed-header
        hide-default-footer
        dense
      >
        <template v-slot:[`item.created_at`]="{ item }">
          <div>{{ formatDate(item.created_at) }}</div>
        </template>
        <template v-slot:[`item.updated_at`]="{ item }">
          <div>{{ formatTime(item.created_at) }}</div>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.custom-switch {
  transform: scale(2.2) translateX(8px);
}

.per-page-wrapper {
  display: inline-flex;
  color: #a29f9d;
  font-size: 12px;
  font-weight: bold;
  border: 1px solid #d7d4d3;
  border-radius: 12px;

  div:nth-child(1),
  div:nth-child(3) {
    display: flex;
    width: 65px;
    align-items: center;
    justify-content: center;
    // padding: 6px 14px;
  }
  div:nth-child(3) {
    cursor: pointer;
  }
}

.c-datatable-header {
  input {
    background-color: #ffffff !important;
    border: 1px solid #d7d4d3 !important;
    border-left: 0px solid #d7d4d3 !important;
    border-radius: 0px 8px 8px 0px !important;
  }
}

.tab-item {
  padding: 5px;
  width: 80px;
  margin: 0px 5px;
  color: #06b56f;
  font-size: 12px;
  text-align: center;
  border: 1px solid #06b56f;
  border-radius: 8px;
  cursor: pointer;
}
.tab-item-active {
  color: #ffffff;
  font-weight: bold;
  background: #06b56f;
}

.c-datatable-page {
  .prev-next-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 33px;
    height: 33px;
    border-radius: 4px;
    cursor: pointer;

    img {
      display: block;
      height: 16px;
    }
  }
}
</style>

<script>
import Moment from "moment";
import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

export default {
  props: ["idSurvey"],
  components: {},
  data() {
    return {
      search: "",
      columnName: "created_at",
      isDescending: true,
      headers: [
        { text: "Survey Updated", value: "created_at", width: "120px" },
        { text: "Time Updated", value: "updated_at", width: "120px" },
        { text: "Updated by", value: "name_user", width: "100%" }
        // { text: 'Action', value: 'actions', sortable: false },
      ],
      data: [],
      selectedData: {},

      loading: true,
      page: 1,
      perPage: 10,

      errors: []
    };
  },

  mounted() {
    ApiService.get(
      `/api/logs?id_survey=${this.idSurvey}&token=${JwtService.getToken(
        "survey"
      )}`,
      "survey"
    )
      .then(({ data }) => {
        if (data?.response) {
          this.data = data?.response?.arrSurveyor;
        }
      })
      .finally(() => {
        this.loading = false;
      });
  },

  methods: {
    formatDate(date) {
      return Moment(date).format("DD-MM-YYYY");
    },
    formatTime(date) {
      return Moment(date).format("HH:mm:ss");
    }
  }
};
</script>
